import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import { Row, Col } from 'react-bootstrap';
import { burger_items } from '../menuItems/items';

const BEM = new BEMHelper('menu');

export default class BurgerMenu extends Component {
  render() {
    const burgers = burger_items.map((i, index) => {
      return (
        <Row {...BEM('listItem', '', 'no-gutters')} key={index}>
          <Col md={7}>
            <div {...BEM('burgerHeading')}>{i.title}</div>
          </Col>
          <Col md={5}>
            <p {...BEM('', 'description')}>{i.description}</p>
          </Col>
          {i.listing.map((l, key) => {
            return (
              <div {...BEM('burgerContainer')} key={key}>
                <Row {...BEM('', '', 'no-gutters')}>
                  <Col md={7}>
                    <div {...BEM('', 'burgername')}> {l.name}</div>
                  </Col>
                  <Col md={5} {...BEM('', '', 'no-gutters')}>
                    <div {...BEM('', 'price')}>
                      {Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR',
                      }).format(l.price)}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Row>
      );
    });
    return (
      <div {...BEM('burgerBlock')}>
        <h1>BURGERS</h1>
        {burgers}
      </div>
    );
  }
}
