import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import BEMHelper from 'react-bem-helper';
const BEM = new BEMHelper('menu');

export default class Extras extends Component {
  render() {
    return (
      <div>
        <h3>Build your own:</h3>
        <Row {...BEM('listItem', '', 'no-gutters')}>
          <Col md={7}>
            <div {...BEM('', 'name')}>Margareta </div>
            <div {...BEM('', 'description')}>tomato base sauce with cheese</div>
          </Col>
          <Col md={5} {...BEM('', '', 'no-gutters')}>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'size')}>S</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>M</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>L</div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 24.90</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 46.90</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 62.90</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <h4>Choose your toppings:</h4>
        <Row {...BEM('listItem', '', 'no-gutters')}>
          <Col md={7}>
            <div {...BEM('', 'name_extra')}>
              Onions, Green pepper, Red pepper, fresh chilli, Mushrooms, Garlic,
              Caramelized onion, Jalapeños
            </div>
          </Col>
          <Col md={5} {...BEM('', '', 'no-gutters')}>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'size')}>S</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>M</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>L</div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 4.00</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 8.00</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 11.00</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row {...BEM('listItem', '', 'no-gutters')}>
          <Col md={7}>
            <div {...BEM('', 'name_extra')}>
              Mince, BBQ chicken, Salami, Bacon, Pepperoni, Ham, Anchovy,
              Peperdew, Blue cheese, Feta, Capers, Olives, Cheese, Pineapple
            </div>
          </Col>
          <Col md={5} {...BEM('', '', 'no-gutters')}>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'size')}>S</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>M</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'size')}>L</div>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 6.00</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 10.50</div>
              </Col>
              <Col xs={4}>
                <div {...BEM('', 'price')}>R 15.50</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
