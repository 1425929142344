export const headings = ['', 'Item', 'Size', 'Price'];
export const menuList = [
  {
    new: false,
    featured: false,
    name: 'ham & mushroom',
    description: '',
    size: ['S', 'M', 'L'],
    price: [32.9, 59.9, 76.9],
  },
  {
    new: true,
    featured: true,
    name: 'ham & pineapple',
    description: '',
    size: ['S', 'M', 'L'],
    price: [34.9, 65.9, 82.9],
  },
  {
    new: false,
    featured: false,
    name: 'ham, bacon, salami & mince',
    description: '',
    size: ['S', 'M', 'L'],
    price: [42.9, 76.9, 94.9],
  },
  {
    new: true,
    featured: false,
    name: 'mince, jalapeno, onion & green pepper',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'pepperoni & extra cheese',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'pepperoni, red pepper & capers',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 72.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'pepperoni & caramelised onion',
    description: '',
    size: ['S', 'M', 'L'],
    price: [35.9, 67.9, 85.9],
  },
  {
    new: false,
    featured: true,
    name: 'pepperoni, feta & fresh chili',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'chicken & 1000 island sauce',
    description: '',
    size: ['S', 'M', 'L'],
    price: [34.9, 65.9, 82.9],
  },
  {
    new: false,
    featured: false,
    name: 'bbq chicken, onion & pineapple',
    description: '',
    size: ['S', 'M', 'L'],
    price: [38.9, 73.9, 90.9],
  },
  {
    new: false,
    featured: false,
    name: 'bbq chicken, mushroom & peperdew',
    description: '',
    size: ['S', 'M', 'L'],
    price: [38.9, 73.9, 90.9],
  },
  {
    new: false,
    featured: false,
    name: 'bacon, feta & garlic',
    description: '',
    size: ['S', 'M', 'L'],
    price: [35.9, 67.9, 85.9],
  },
  {
    new: false,
    featured: false,
    name: 'salami, olives, mushroom & green pepper',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'salami jalapeño & blue cheese',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'anchovy, mushrooms & garlic',
    description: '',
    size: ['S', 'M', 'L'],
    price: [34.9, 69.9, 83.9],
  },
  {
    new: false,
    featured: false,
    name: 'anchovy, olives, capers & garlic',
    description: '',
    size: ['S', 'M', 'L'],
    price: [41.9, 74.9, 92.9],
  },
  {
    new: false,
    featured: false,
    name: 'seafood (shrimps, mussels, calamari & garlic)',
    description: '',
    size: ['S', 'M', 'L'],
    price: [38.9, 73.9, 90.9],
  },
];

export const burger_items = [
  {
    new: false,
    featured: false,
    title: 'original burgers',
    description: '100g patty',
    listing: [
      { name: 'kiddies (onion & gherkins only)', price: 25.9 },
      { name: 'original', price: 31.9 },
      { name: 'cheese', price: 35.9 },
      { name: 'bacon & cheese', price: 41.9 },
      { name: 'chilli cheese', price: 38.9 },
      { name: 'bacon, chilli, cheese', price: 44.9 },
    ],
  },
  {
    new: false,
    featured: false,
    title: 'double-up burgers',
    description: '2 x 100g patties',
    listing: [
      { name: 'straight-up (onions & gherkins only)', price: 49.9 },
      { name: 'original', price: 53.9 },
      { name: 'cheese', price: 62.9 },
      { name: 'bacon & cheese', price: 73.9 },
      { name: 'chilli cheese', price: 64.9 },
      { name: 'bacon, chilli, cheese', price: 76.9 },
    ],
  },
  {
    new: false,
    featured: false,
    title: 'hand-cut potato chips',
    description: '',
    listing: [
      { name: 'regular', price: 15.0 },
      { name: 'medium', price: 25.0 },
      { name: 'large', price: 35.0 },
    ],
  },
  {
    new: false,
    featured: false,
    title: 'spare ribs',
    description: '',
    listing: [
      { name: '300g ribs & chips', price: 75.0 },
      { name: '500g ribs & chips', price: 105.0 },
    ],
  },
];
