import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import './menuItems/Menu.scss';
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import { menuList } from './menuItems/items';
import newItem from './assets/new1.png';
import orderNow from './assets/ordernow.png';
import Header from './Header/Header';
import Extras from './Extras/Extras';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import axios from 'axios';

const BEM = new BEMHelper('menu');
const API_PATH = './api/Contact/index.php';

interface FormValue {
  fname: string;
  lname: string;
  email: string;
  message: string;
  mailSent: boolean;
  error: null;
}

export default class App extends Component<{}, FormValue> {
  constructor(props: any) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      message: '',
      mailSent: false,
      error: null,
    };
  }
  handleFormSubmit = (e: any) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
        });
      })
      .catch((error) => this.setState({ error: error.message }));
  };
  render() {
    const pizza = menuList.map((i, index) => {
      return (
        <Row
          key={index}
          {...BEM(
            'listItem',
            '',
            index / 2 ? 'no-gutters odd' : 'no-gutters even'
          )}
        >
          <Col md={7}>
            {i.new && (
              <div {...BEM('newIcon')}>
                <img src={newItem} alt='' />
              </div>
            )}
            {i.featured && (
              <div {...BEM('ordernow')}>
                <img src={orderNow} alt='' />
              </div>
            )}
            <div {...BEM('', 'name')}> {i.name}</div>
            <div {...BEM('', 'description')}>{i.description}</div>
          </Col>
          <Col md={5} {...BEM('', '', 'no-gutters')}>
            <Row>
              {i.size.map((s, index) => {
                return (
                  <Col xs={4} key={index}>
                    <div {...BEM('', 'size')}>{s}</div>
                  </Col>
                );
              })}
            </Row>
            <Row>
              {i.price.map((p, index) => {
                return (
                  <Col xs={4} key={index}>
                    <div {...BEM('', 'price')}>
                      {Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR',
                      }).format(p)}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      );
    });

    return (
      <div {...BEM()}>
        <Container>
          <Header />
          <div {...BEM('containerBlock')}>
            <h1>PIZZAS</h1>
            {pizza}
            <Extras />
            <BurgerMenu />
            <p>Contact Me</p>
            <div>
              <form action='#'>
                <label>First Name</label>
                <input
                  type='text'
                  id='fname'
                  name='firstname'
                  placeholder='Your name..'
                  value={this.state.fname}
                  onChange={(e) => this.setState({ fname: e.target.value })}
                />
                <label>Last Name</label>
                <input
                  type='text'
                  id='lname'
                  name='lastname'
                  placeholder='Your last name..'
                  value={this.state.lname}
                  onChange={(e) => this.setState({ lname: e.target.value })}
                />
                <label>Email</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Your email'
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />

                <label>Message</label>
                <textarea
                  id='message'
                  name='message'
                  placeholder='Write something..'
                  onChange={(e) => this.setState({ message: e.target.value })}
                  value={this.state.message}
                ></textarea>
                <input
                  type='submit'
                  onClick={(e) => this.handleFormSubmit(e)}
                  value='Submit'
                />
                <div>
                  {this.state.mailSent && (
                    <div>Thank you for contcting us.</div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
