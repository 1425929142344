import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import logo from '../assets/logo.png';
import banner from '../assets/banner.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

const BEM = new BEMHelper('menu');

export default class Header extends Component {
  render() {
    return (
      <div>
        <div {...BEM('tel')}>
          <FontAwesomeIcon icon={faPhoneAlt} {...BEM('telIcon')} />
          <a href='tel:0422910419'>(042) 291 0419</a>
        </div>
        <div {...BEM('banner')}>
          <img src={banner} alt='' />
        </div>
        <div {...BEM('logo')}>
          <img src={logo} alt='' />
        </div>
      </div>
    );
  }
}
